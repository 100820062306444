export function copyToClipboard(
  textToCopy: string,
  cb: (err?: any) => void = () => ({})
): void {
  const textArea = document.createElement("textarea");
  textArea.value = textToCopy;
  document.body.appendChild(textArea);
  textArea.select();

  try {
    document.execCommand("copy");
    cb();
  } catch (err) {
    console.error("Error copying text:", err);
    cb(err);
  } finally {
    document.body.removeChild(textArea);
  }
}