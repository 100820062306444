<template>
  <div class="category">
    <slot />
  </div>
</template>

<script lang="ts" setup></script>

<style lang="scss" scoped>
.category {
  @apply pt-5 pb-4;
  @apply mb-2;
}
</style>
